.page-content {
	padding-top: 300px;
	padding-bottom: 185px;
	@include sp {
		padding-top: 150px;
		padding-bottom: 90px;
	}
	.movie-inner {
		& > p {
			@include font-size(21);
			text-align: center;
			margin-bottom: 35px;
			color: #5b5959;
			@include sp {
				@include font-size(14);
				margin-bottom: 20px;
			}
		}
	}
	.ttl-style01 {
		margin-bottom: 140px;
		span {
			@include font-size(77)
		}
		@include sp {
			margin-bottom: 70px;
			span {
				@include font-size(34)
			}
		}
	}
	.listFillter {
		display: flex;
		justify-content: center;
		@include font-size(18);
		margin-bottom: 50px;
		margin-top: 140px;
		flex-wrap: wrap;
		@include sp {
			@include font-size(12);
			margin: 70px 0px 50px;
			justify-content: unset;
			padding: 0px 30px;
		}
		li {
			&:hover,&.active{
				span, a{
					&:after {
						display: block;
					}
				}
			}
			span, a {
				display: inline-block;
				padding: 0px 10px;
				position: relative;
				cursor: pointer;
				&:after {
					content: "";
					position: absolute;
					bottom: 2px;
					width: 100%;
					height: 1px;
					left: 0;
					background: #737373;
					display: none;
				}
				@include sp {
					padding: 5px 10px;
				}
			}
		}
	}
	.listMovies {
		padding: 0px 2.5%;
		display: flex;
		flex-wrap: wrap;
		@include sp {
			padding: 0px 20px;
		}
		.tab-content {
			margin-right: 3.33%;
			overflow: hidden;
			width: calc((100% - 6.66%) / 3);
			margin-bottom: 4%;
			@include sp {
				width: 100%;
				margin-right: 0;
				margin-bottom: 30px;
			}
			&:nth-child(3n) {
				margin-right: 0;
			}
			.img-thumb01 {
				overflow: hidden;
				img {
					transition: all 0.3s;
				}
			}
			&:hover {
				img {
					transform: scale(1.1);
					transition: all 0.3s;
				}
			}

			.img-thumb {
				position: relative;
				padding-bottom: 56.25%;
				height: 0;
				overflow: hidden;
			}
			iframe {
				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 100%;
			}
			.item-content {
				padding: 0px 10px;
				text-align: center;
				.ttl {
					font-weight: bold;
					@include font-size(18);
					text-align: center;
					padding: 15px 0px 20px;
					cursor: pointer;
					@include sp {
						@include font-size(16);
					}
				}
			}

			.img-thumb {
				overflow: hidden;
				img {
					transition: all 0.3s;
				}
			}
			&:hover {
				img {
					transform: scale(1.1);
					transition: all 0.3s;
				}
			}
		}
	}
}

#page-movie {
	background: #d9deeb;
}

#page-photo {
	background: #d1e1ec;
	.listMovies {
		@include sp {
			padding: 0px 40px;
		}
	}

}
