#page-qa {
	background-color: #E6EBE3;
}

.qa-request {
	max-width: 870px;
	margin: auto;
	padding-left: 20px;
	padding-right: 20px;
	li {
		padding-bottom: 30px;
		padding-top: 30px;
		border-bottom: 1px solid #ccc;
		@include sp {
			padding-bottom: 17px;
			padding-top: 17px;
		}
		&:last-child {
			padding-bottom: 0;
			border-bottom: 0;
		}
		&:first-child {
			padding-top: 0;
		}
		p {
			@include font-size(20);
			padding-left: 25px;
			color: #5B5959;
			position: relative;
			@include sp {
				@include font-size(16);
				padding-left: 20px;
			}
			&::before {
				content: "";
				background-image: url("../img/faq/question.png");
				background-repeat: no-repeat;
				background-position: 50% 50%;
				background-size: contain;
				width: 22px;
				height: 25px;
				top: 50%;
				left: 0;
				position: absolute;
				transform: translate(0, -50%);
				@include sp {
					width: 17px;
					height: 20px;
				}
			}
		}

		.answer {
			background-color: #fff;
			padding: 25px 40px;
			margin-top: 20px;
			@include font-size(15);
			@include sp {
				padding: 12px 30px;
				@include font-size(14);
			}

			&:before {
				left: 13px;
				background-image: url("../img/faq/answer.png");
				@include sp {
					left: 10px;
				}
			}
			display: none;
		}
	}
	.toggle {
		cursor: pointer;
	}

	padding-bottom: 185px;
	@include sp {
		padding-bottom: 50px;
	}
}