/*
#overview
モジュールスタイルガイド

これはサイト全体に適用されるモジュールのパーツ群です。
必ずこちらのパーツを使用してコーディングするようにお願いします。
*/

/*
#colors
@背景色 #996600
@文字色 #333
@リンク #ccc
*/

/*
#styleguide
パンくずモジュール

パンくずのモジュールです。

@depulicated
@非推奨
@todo
@common

```
<div class="breadcrumb">
<a href='/'>トップページ</a> <i class='fa fa-angle-right' aria-hidden='true'></i> <span>ページ1</span>
</div>

```

*/

/*
#styleguide
見出しモジュール　識別子はttl

見出しモジュールは、セクションごとの見出しとなる要素（HTML5の分類で、「ヘッディングコンテンツ(Heading Content)」となるh1やh2などの要素、見出しとみなすことが出来そうなp要素の事です。
クラス名として識別子、「 ttl 」をつけて管理します。

```
<h1 class="ttlCom01"><span class="ttlCom01In">見出しモジュール</span></h1>
<h2 class="ttlCom02">見出しモジュール</h2>
<h3 class="ttlCom03">見出しモジュール</h3>

```

*/

/*
#styleguide
テキストモジュール　識別子はtxt

テキストモジュールは、p要素やテキストに該当するspan要素などのコンテンツ内の本文のことです。
基本的に、サイト内のテキストはテキストモジュールに該当します。クラス名として識別子「txt」をつけます。

```
<p class="txtBasic">テキストモジュール、識別子は～</p>

```

*/

/*
#styleguide
リンクモジュール　識別子はlink

リンクモジュールは、基本的にa要素のようにリンクが関係するモジュールの事です。クラス名として識別子「link」をつけます。
通常は、テキスト内にリンクが存在する場合にモジュールとして分ける事が多いです。

```
<a href="" class="linkCmn">OOCSS</a>

```

*/

/*
#styleguide
ボタンモジュール　識別子はbtn

ボタンモジュールは、見た目や役割がボタン上の要素の事です。クラス名をつける要素は特に指定はありませんが、button要素やa要素、a要素を囲むdiv要素に付与するパターンが多いです。
クラス名として識別子「btn」をつけます。

```
<div class="btn btnRed">ボタン</div>
<br>
<div class="btn btnBlue">ボタン</div>

```

*/

/*
#styleguide
リストモジュール　識別子はlist

リストモジュールは、リスト状の同じものが連続して並ぶものが該当します。基本的には、ul、ol、dl要素の様な明確にリスト分けされるものが該当しますが、div要素でも構いません。クラス名として識別子「list」をつけます。
また、クラス名を付与する場所はリストの親要素、つまり連続するリストの子要素がliだとすると、ul要素やol要素に識別子「list」を付与したクラス名をつけます。

```
<ul class="listOption">
	<li class="item">HTML</li>
	<li class="item">CSS</li>
	<li class="item">JavaScript</li>
</ul>

```

*/

/*
#styleguide
ボックスモジュール　識別子はbox

ボックスモジュールは、ボックス状のものが該当します。基本的には見出しやテキストなどの要素を含み込むボーダー付きの箱をボックスモジュールとしてみなします。クラス名として識別子「box」をつけます。

```
<div class="boxReading">
	<h2 class="ttlCom01">ボックスモジュールとは</h2>
		<p class="txtCom01">モジュールコーディングという考え方～</p>
		<a href="" class="linkCom01">続きを読む</a>
</div>

```

*/

/*
#styleguide
グリッドモジュール　識別子はgrid（子要素にはcol)

2カラムや3カラムなど、カラム分けするレイアウトのモジュールが該当します。識別子「grid」を付与します。
子要素には「col」を付与します。基本的に、親に「gridXXX」というメインクラスを付与して、「column-2」や「column-3」などカラム数と対応する数字込みのサブクラスを付与します。

```

<div class="gridCom column-3">
	<div class="col">
		<img class="imageCodingDetail" src="img/XXX.jpg">
	<h2 class="ttlCodingDetail">コーディングの秘訣その1</h2>
	<p class="txtCodingDetail">モジュールコーディングという考え方は～</p>
	</div>
	<div class="col">
		<img class="imageCodingDetail" src="img/XXX.jpg">
	<h2 class="ttlCodingDetail">コーディングの秘訣その1</h2>
	<p class="txtCodingDetail">モジュールコーディングという考え方は～</p>
	</div>
	<div class="col">
		<img class="imageCodingDetail" src="img/XXX.jpg">
	<h2 class="ttlCodingDetail">コーディングの秘訣その1</h2>
	<p class="txtCodingDetail">モジュールコーディングという考え方は～</p>
	</div>
</div>


```

*/

/*
#styleguide
FORMモジュール　

フォーム用の部品になります。

```

<form actione="">
<dl>
	<dt>テキスト</dt>
	<dd><input type="text" name="" value=""></dd>
	<dt>パスワード</dt>
	<dd><input type="password" name="passwd" value=""></dd>
	<dt>ラジオボタン</dt>
	<dd><input type="radio" name="" value="" checked="checked" />テスト1
	<input type="radio" name="" value="" />テスト2
	<input type="radio" name="" value="" />テスト3</dd>
	<dt>チェックボックス</dt>
	<dd><input type="checkbox" name="" value="" checked="checked" />テスト1
	<input type="checkbox" name="" value="" />テスト2
	<input type="checkbox" name="" value="" checked="checked" />テスト3</dd>
	<dt>プルダウン</dt>
	<dd><select name="">
	<option value="" selected="selected">テスト1</option>
	<option value="">テスト2</option>
	<option value="">テスト3</option>
	</select></dd>
	<dt>テキストエリア</dt>
	<dd><textarea name="" rows="5" cols="20">テキストエリア</textarea></dd>
</dl>
<div>
	<input type="button" name="btn1" value="汎用ボタン">
	<input type="reset" name="btn2" value="リセット" >
	<input type="submit" name="btn3" value="送信" >
</div>
</form>


```

*/

/* ====================================================
MODULE
==================================================== */

/*パンくずMODULE*/

.breadcrumb {
	
}

/*見出しMODULE*/
.ttlCom01 {
	
}

.ttlCom02 {
	
}

.ttlCom03 {
	
}

/*テキストMODULE*/
.txtBasic{
	
}
.txt-center {
	text-align: center;
}

.txt-right {
	text-align: right;
}

.txt-left {
	text-align: left;
}

/*リンクMODULE*/
.linkCmn {
	
}

/*ボタンMODULE*/
.btn {
	width: 100px;
	height: 50px;
	border: 2px solid #FFCCCC;
	text-align: center;
	line-height: 50px;
}

.btnRed {
	color: red;
	background-color: #FFCCCC;
}

.btnBlue {
	color: white;
	border-color: blue;
	background-color: blue;
}

/*リストMODULE*/
.listOption {
	
}

/*ボックスMODULE*/
.boxReading{
	
}

/*グリッドMODULE*/
.gridCom {
	
}

/*フォームMODULE*/
.dl.form dl,dt,dd {
	
}

.btn-style01{
	background: #ffffff;
	@include font-size(14.5);
	color: #000;
	text-align: center;
	display: block;
	max-width: 300px;
	width: 100%;
	line-height: 40px;
	transition: all 0.3s;
	@include sp{
		margin: auto;
		max-width: 200px;
		line-height: 30px;
	}
	&:hover{
		background: #e6e6e6;
		transition: all 0.3s;
	}
}

.ttl-style01{
	display: block;
	text-align: center;
	margin-bottom: 30px;
	@include sp{
		margin-bottom: 20px;
	}
	span{
		@include font-size(20);
		text-align: center;
		letter-spacing: 10px;
		display: inline-block;
		position: relative;
		line-height: 1;
		@include sp{
			@include font-size(20);
		}
		&:after{
			content: '';
			position: absolute;
			height: 2px;
			background: #737373;
			width: 100%;
			left: -3px;
			bottom: -2px;
			@include sp{
				left: -5px;

			}
		}
	}
}

.ttl-style02 {
	@include font-size(30);
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 60px;
	line-height: 1;
	@include sp {
		@include font-size(16);
		margin-bottom: 30px;
	}
	span {
		@include font-size(12);
		text-transform: lowercase;
	}
}

.ttl-style03 {
	text-align: center;
	margin-bottom: 140px;
	@include sp {
		margin-bottom: 55px;
		img {
			width: 50%
		}
	}
	span {
		padding-bottom: 10px;
	}
}

input,textarea,select{
	background: #f2f2f2;
	border: none;
	display: block;
	width: 100%;
	padding: 0px 10px;
}

textarea{
	resize: none;
	height: 285px;
	padding-top: 10px;
	@include sp{
		height: 190px;
	}
}

input,select{
	height: 35px;
}