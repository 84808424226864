/* ====================================================
Font
==================================================== */
$font-gothic: "ヒラギノ角ゴPro W3","Hiragino Kaku Gothic Pro", Osaka,"Meiryo","メイリオ","MS Pゴシック",Arial, Helvetica, Verdana,sans-serif;
$font-mincho: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "游明朝", "游明朝体", YuMincho, "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
$font-base:"Times New Roman", "游明朝", "游明朝体", YuMincho, "Yu Mincho",   "ＭＳ Ｐ明朝", "MS PMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "sans-serif";
@mixin font-size($sizeValue) {
	font-size: ($sizeValue) + px;
	font-size: ($sizeValue / 10) + rem;
}

@mixin font-vw($sizeValue) {
	font-size: ($sizeValue) + px;
	font-size: ($sizeValue / (750/100)) + vw; /* 750 = design width, mobile only */
}


$mobile: "only screen and (max-width: 767px)";
$pc: "only screen and (min-width: 768px)";
$tb-sp: "only screen and (max-width: 1200px)";


@mixin sp() {
	@media #{$mobile} {
		@content;
	}
}

@mixin pc() {
	@media #{$pc} {
		@content;
	}
}

@mixin tb-sp() {
	@media #{$tb-sp} {
		@content;
	}
}


/* ====================================================
Position & transform
==================================================== */
@mixin v-align {
	top: 50%;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

@mixin h-align {
	left: 50%;
	-webkit-transform: translateX(-50%);
	   -moz-transform: translateX(-50%);
	    -ms-transform: translateX(-50%);
	     -o-transform: translateX(-50%);
	        transform: translateX(-50%);
}

@mixin v-h-align {
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	   -moz-transform: translate(-50%, -50%);
	    -ms-transform: translate(-50%, -50%);
	     -o-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

@mixin smooth-transition {
	-webkit-backface-visibility: hidden;
	-webkit-transition: all .3s ease;
	   -moz-transition: all .3s ease;
	    -ms-transition: all .3s ease;
	     -o-transition: all .3s ease;
	        transition: all .3s ease;
}

/* ====================================================
Color
==================================================== */

/* ====================================================
Sizing
==================================================== */
$base-size: 16px;
@function px-em($target, $context: $base-size) {
	@if $target == 0 {
		@return 0
	}
	@return $target / $context + 0em;
}

@function calc-vw($size) {
	$vwSize: ($size / 640) * 100;
	@return #{$vwSize}vw;
}

/* ====================================================
Misc
==================================================== */
@mixin no-select {
	 -khtml-user-select: none;
	-webkit-user-select: none;
	   -moz-user-select: none;
	     -o-user-select: none;
	        user-select: none;
}
