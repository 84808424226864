/* ====================================================
Base style & container
==================================================== */
body {
	color: black;
	font-size: 100%;
	-webkit-font-smoothing: antialiased;
	line-height: 1.4;
	font-family: $font-base;
	@include font-size(14);
	background: #d3e5df;

	transition:background-color 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);

}

.container {
	display: block;
	margin: 0 auto;
	&.container-sm {
		max-width: 1030px;
		padding: 0 15px;
	}
	&.container-md {
		max-width: 1230px;
		padding: 0 20px;
	}
	&.container-lg {
		max-width: 1530px;
		padding: 0 25px;
	}
}

.pc {
	@media screen and (max-width: 767px) {
		display: none!important;
	}
}

.sp {
	@media screen and (min-width: 768px) {
		display: none!important;
	}
}

/* ====================================================
Float & clear
==================================================== */
.clearfix {
	*zoom: 1;
	&:before {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
		content: "";
		display: table;
	}
}