#page-photo02 {
	background-color: #D1E1EC;

	.img-weding {
		max-width: 560px;
		margin: 0 auto;
		padding-bottom: 180px;
		@include sp {
			max-width: 100%;
			padding: 0 25px;
			padding-bottom: 50px;
		}
		img {
			margin-bottom: 20px;
			pointer-events: none;
			@include sp {
				margin-bottom: 15px;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.sec-photo {
	padding-top: 300px !important;
	@include sp {
		padding-top: 85px !important;;
	}
}