.mv {
	height: calc(100vh + 80px);
	position: relative;
	padding: 0px 0px 100px;
	//background: rgb(211, 229, 223);
	@include sp {
		height: calc(100vh);
		padding-bottom: 0;
		padding-top: 42px;
	}
	.mv-inner {
		max-width: 1200px;
		margin: auto;
		.ttl-mv {
			position: absolute;
			top: 50%;
			left: 25%;
			transform: translate(-50%, -50%);
			@include font-size(38);
			width: 0px;
			word-break: break-all;
			z-index: 99;
			span {
				display: inline-block;
				opacity: 0;
				&.on{
					animation: mymove 2s;
					&.span02{
						animation-delay: 0.2s;
					}
					&.span03{
						animation-delay: 0.4s;
					}
					&.span04{
						animation-delay: 0.6s;
					}
					&.span05{
						animation-delay: 0.8s;
					}
					&.span06{
						animation-delay: 1s;
					}
					&.span07{
						animation-delay: 1.2s;
					}
					&.span08{
						animation-delay: 1.4s;
					}
					&.span09{
						animation-delay: 1.6s;
					}
				}
			}
			@media screen and(max-width: 900px) {
				left: 15%;
			}
			@include sp {
				@include font-size(20);
				left: 50%;
				font-weight: bold;
			}
		}

	}

	@include sp {
		img {
			width: 100%;
			height: 100vh;
			object-fit: cover;
			height: calc(100vh - 42px);
		}
		.mv-video {
			position: absolute;
			height: 0;
			overflow: hidden;
			top: 40px;
			padding-bottom: 100vh;
			width: 100%;
			right: 0;

		}
	}
	@media screen and(max-width: 900px) {
		.mv-video {
			right: 4% !important;
		}
	}
	@include pc {
		.mv-video {
			position: relative;
			padding-bottom: calc(100vh - 60px);
			height: 0;
			top: 40px;
			right: 10%;
			overflow: hidden;
			max-width: 100%;
		}

		.mv-video iframe,
		.mv-video object,
		.mv-video embed,
		.mv-video video {
			position: absolute;
			top: 0;
			right: 0;
			width: unset;
			height: 100%;
		}
	}

}

.ss-wedding {
	//background: #f0efdd;
	padding-bottom: 100px;
	@include sp {
		padding-bottom: 0px;
	}
	.ss-wedding-inner {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		@include font-size(17);
		position: relative;
		@include sp {
			flex-direction: column-reverse;
			@include font-size(13);
		}
		.wedding-l {
			width: 58.3%;
			z-index: 1;
			background: url("../img/index/kusakinafilm-1-11.jpg") no-repeat center/cover;
			background-position: left 70% center;
			@include sp{
				height: 350px;
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				display: none;
			}
			@include sp {
				width: 100%;
			}
		}
		.wedding-r {
			width: calc(100% - 58.3%);
			padding: 0px 30px 5.41% 4.16%;
			line-height: 2;
			z-index: 2;
			@media screen and(max-width: 1024px){
				padding: 0px 10px 5.41% 2.16%;
			}
			@media screen and(max-width: 800px){
				padding: 0px 15px 5.41% 4.16%;
			}
			@include sp {
				width: 100%;
				padding: 55px 15px 55px 30px;
			}
			.ttl {
				@include font-size(45);
				line-height: 1.4;
				color: #737373;
				text-decoration: underline;
				margin: 170px 0px 160px -180px;
				text-underline-position: under;
				@media screen and(max-width: 1060px) {
					@include font-size(32)
				}
				span.span01 {

				}
				span.span02 {
					display: inline-block;
					margin-left: 80px;
					text-decoration: underline;
					text-underline-position: under;
				}
				@include sp {
					@include font-size(22);
					margin: 0 0px 45px;
					span {
						margin-left: 20px;
					}
					span.span02 {
						margin-left: 40px;
					}
				}
			}
		}
	}
}

.ss-movie {
	//background: #f3e3cc;
	padding-bottom: 100px;
	@include sp {
		padding-bottom: 0px;
	}
	&.ss-photo {
		//background: #e6eee6;
		.ss-movie-inner {
			flex-direction: row-reverse;
			.ss-movie-r {
				overflow: hidden;
				a {
					overflow: hidden;
					display: inline-block;
				}
				img {
					transition: all 0.3s;
				}
				&:hover {
					img {
						transform: scale(1.1);
						transition: all 0.3s;
					}
				}
			}
		}
		.listMovies {
			padding: 0px 6.6%;
			@include sp {
			}
			.item {
				margin-right: 5.83%;
				overflow: hidden;
				width: calc((100% - 11.66%) / 3);
				.img-thumb {
					overflow: hidden;
					img {
						transition: all 0.3s;
					}
				}
				&:hover {
					img {
						transform: scale(1.1);
						transition: all 0.3s;
					}
				}
			}
		}

	}
	.ss-movie-inner {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		.ss-movie-l {
			width: calc(100% - 59.2%);
			padding: 5.83%;
			@include font-size(17);
			.ttl-style01 {
				text-align: left;
				margin-bottom: 60px;
				span {
					@include font-size(60);
					color: #737373;
					display: inline-block;
					letter-spacing: 25px;
					@include sp{
						letter-spacing: 15px;
					}
					@media screen and(max-width: 1060px) {
						@include font-size(40)
					}
					&:after {
						left: -10px;
					}
				}
			}
			.btn-style01 {
				margin-top: 65px;
			}
			@include sp {
				width: 100%;
				padding: 35px 10px;
				text-align: center;
				@include font-size(14);
				.ttl-style01 {
					text-align: center;
					margin-bottom: 30px;
					span {
						@include font-size(30)
					}
				}
				.btn-style01 {
					margin: 35px auto 0px;
				}
			}
		}
		.ss-movie-r {
			width: 59.2%;
			@include sp {
				width: 100%;
				.description {
					@include font-size(16);
					text-align: center;
					padding: 15px 0px 40px;
				}
			}
			img {
				width: 100%;
				@include pc {
					height: 100%;
					object-fit: cover;
				}
			}
			.img-thumb {
				position: relative;
				padding-bottom: 56.25%;
				height: 0;
				overflow: hidden;
				@include sp{
					padding-bottom: 56.25%;
				}
				iframe {
					position: absolute;
					top: 0px;
					left: 0px;
					width: 100%;
					height: 100%;
				}
				.vp-center {
					align-items: baseline;
				}
				div#player {
					height: 100%;
				}
			}

		}
	}
	.listMovies {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		margin-top: 100px;
		padding: 0px 3.75%;
		@include sp {
			margin-top: 30px;
			display: block;
		}
		&.vimeoVideo {
			.item {
				.img-thumb {
					position: relative;
					padding-bottom: 56.25%;
					height: 0;
					overflow: hidden;
				}
				iframe {
					position: absolute;
					top: 0px;
					left: 0px;
					width: 100%;
					height: 100%;
				}
			}
		}
		.item {
			margin-right: 4.16%;
			width: calc((100% - 8.32%) / 3);
			img {
				width: 100%;
				@include pc {
					height: 100%;
					object-fit: cover;
				}
			}

			&:last-child {
				margin-right: 0;
			}
			.description {
				text-align: center;
				margin-top: 30px;
				@include font-size(15);

			}
		}
	}
}

.ss-office {
	//background: #ffffff;
	padding-bottom: 100px;
	@include sp {
		padding-bottom: 70px;
	}
	.mv-video {
		//height: 675px;
		//overflow: hidden;
		//width: 100%;
		position: relative;
		video{
			display: block;
			width: 100%;
		}

	}
	.ss-office-inner {
		.listChoice {
			margin-top: 6.67%;
			margin-bottom: 8.3%;
			display: flex;
			flex-wrap: nowrap;
			padding: 0px 10.83%;
			@media screen and(max-width: 1000px){
				padding: 0px 3.8%;
			}
			@include sp {
				margin: 50px 0px 50px;
				padding: 0px 40px;
				display: block;
			}
			.item {
				margin-right: 8.3%;
				text-align: center;
				width: calc((100% - 16.6%) / 3);
				@media screen and(max-width: 1000px){
					margin-right: 4%;
					width: calc((100% - 8%)/3);
				}
				@include font-size(15);
				a{
					display: unset;
				}
				@include sp {
					margin-right: 0;
					@include font-size(13);
					margin-bottom: 50px;
					width: 100%;
					a{
						display: block;
					}
				}
				&:last-child {
					margin-right: 0;
				}
				.img-thumb {
					margin-bottom: 25px;
					@include sp {
						margin-bottom: 15px;
						padding: 0px 20px;
					}
				}
				.img-thumb {
					overflow: hidden;
					img {
						transition: all 0.3s;
					}
				}
				&:hover {
					img {
						transform: scale(1.1);
						transition: all 0.3s;
					}
				}

			}
		}
		.office-main-content {
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			@include sp {
				flex-direction: column-reverse;
			}
			.office-right {
				width: 65%;
				@include sp {
					width: 100%;
				}
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			.office-left {
				width: 35%;
				padding: 0px 30px 0px 5.83%;
				@include sp {
					width: 100%;
					padding: 25px 40px 0px;
					&>p{
						padding: 0px 15px;
					}
				}
				.ttl-style01 {
					margin-bottom: 50px;
					@include sp {
						margin-bottom: 30px;
					}
					span {
						@include font-size(25);
						letter-spacing: 10px;
						@include sp {
							@include font-size(20)
						}
					}
				}
				p {
					font-family: $font-gothic;
					margin-bottom: 40px;
					@include font-size(13);
					@include sp {
						margin-bottom: 30px;
						@include font-size(14);
					}
				}
				iframe {
					width: 100%;
					height: 215px;
					@include sp {
						height: 155px;
					}
				}
			}
		}
	}
}

.contact {
	//background: #ffffff;
	.contact-inner {
		max-width: 680px;
		padding: 0px 15px;
		margin: auto;
		@include font-size(12);
		& > p {
			@include font-size(13);
			@include sp {
				@include font-size(13);
			}
		}
		.ttl-style01 {
			margin-bottom: 70px;
			span {
				@include font-size(25);
				@include sp {
					@include font-size(20)
				}
			}
		}
		.contact-form {
			margin-top: 6.67%;
			@include font-size(12);
			.contact-item {
				display: flex;
				flex-wrap: nowrap;
				justify-content: space-between;
				border-top: 1px solid #737373;
				padding: 25px 0px;
				@include sp {
					display: block;
					padding: 15px 0px;
				}
				&:last-child, &:first-child {
					border-top: 0;
				}
				.item-l {
					width: 27.5%;
					padding-top: 10px;
					@include sp {
						width: 100%;
						margin-bottom: 10px;
						padding-top: 0;
					}
				}
				.item-r {
					width: 72.5%;
					@include sp {
						width: 100%;
						position: relative;
					}
					.btn-style01 {
						border: 1px solid #737373;
						@include sp {
							& + .err {
								position: static;
								padding-left: 0;
								text-align: center;
								margin-top: 15px;
							}
						}
					}
				}
				.err {
					color: #ff0000;
					margin-top: 8px;
					//display: none;
					@include sp {
						@include font-size(10);
						position: absolute;
						top: -33px;
						padding-left: 65px;
					}
				}
			}
		}
	}
}

.no-ip5 {
	@media(max-width: 320px) {
		display: none !important;
	}
}


#googleMap {
	width: 100%;
	height: 215px;
	@include sp {
		height: 155px;
	}
}