.item-white {
	max-width: 930px;
	margin: auto;
	padding: 0px 15px;
	margin-bottom: 80px;
	@include sp {
		margin-bottom: 35px;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.plan {
	.item-white-inner{
		padding: 65px 60px;
		background: #ffffff;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		@include sp {
			display: block;
			padding: 25px 15px;
		}
	}
	.left {
		width: 40%;
		color: #000;
		@include font-size(25);
		letter-spacing: 15px;
		@include sp {
			width: 100%;
			text-align: center;
			margin-bottom: 40px;
			@include font-size(18);
			letter-spacing: 12px;
		}
	}
	.right {
		width: 60%;
		color: #5B5959;
		@include sp {
			width: 100%
		}
	}
	.item {
		@include font-size(15);
		margin-bottom: 60px;
		@include sp {
			@include font-size(12);
			margin-bottom: 35px;
		}
		span {
			@include font-size(20);
			display: block;
			padding-bottom: 10px;
			border-bottom: 1px solid #ccc;
			margin-bottom: 10px;
			@include sp {
				@include font-size(14)
			}
		}
		p {
			line-height: 1.6;
			position: relative;
			&:before {
				display: inline-block;
				content: '';
				width: 12px;
				height: 12px;
				background-color: #5B5959;
				margin-right: 5px;
				@include sp {
					width: 8px;
					height: 8px;
				}
			}
			&.no-arround {
				@include font-size(12);
				&:before {
					display: none;
				}
				@include sp {
					@include font-size(10);
				}
			}
		}
		&:last-child {
			margin-bottom: 0;
		}
	}

	// Style02
	&.style02 {
		display: block;
		.left,
		.right {
			width: 100%;
		}
		.left {
			margin-bottom: 25px;
		}
		.right {
			span {
				display: block;
				margin-bottom: 50px;
				color: #000;
				@include font-size(15);
				@include sp {
					@include font-size(12);
					margin-bottom: 30px;
				}
			}
			a {
				max-width: 215px;
			}
		}
	}
}

.sub-ttl {
	text-align: center;
	@include font-size(21);
	margin-bottom: 140px;
	@include sp {
		@include font-size(14);
		margin-bottom: 65px;
	}
}

#page-plan {
	background-color: #E6EBE3;
	color: #5B5959;
	
	.sec-planMovie {
		padding-bottom: 185px;
		@include sp {
			padding-bottom: 50px;
		}
	}
}