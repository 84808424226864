#main-header {
	position: fixed;
	z-index: 999;
	@include sp{
		width: 100%;
	}
	@include pc{
		top: 40px;
		left: 25px;
	}
	.logo-wrap {
		position: relative;
		@include sp{
			text-align: center;
			display: block;
			//background: #d3e5df;
			padding: 12px;
		}
		.logo {
			@include sp{
				display: flex;
				align-items: center;
				justify-content: center;

			}
			img {
				width: 230px;
				@media screen and(max-width: 900px){
					width: 160px;
				}
				@include pc{
					margin-bottom: 20px;
				}
				@include sp{
					width: 120px;
					display: block;
					margin: auto;
				}
			}
		}
		.menuBar {
			display: none;
		}
		@include sp {
			.menuBar {
				z-index: 99;
				position: absolute;
				top: -2px;
				right: 12px;
				display: block !important;
				height: 46px;
				span {
					height: 2px;
					display: block;
					width: 30px;
					background: #000000;
					position: relative;
					transition: all 0.3s;
					&:nth-child(1) {
						top: 15px;
					}
					&:nth-child(2) {
						top: 20px;
					}
					&:nth-child(3) {
						top: 25px;
					}
				}
				&.on {
					transition: all 0.3s;
					span {
						&:nth-child(1) {
							top: 24px;
							transform: rotate(45deg);
						}
						&:nth-child(2) {
							opacity: 0;
						}
						&:nth-child(3) {
							top: 20px;
							transform: rotate(-45deg);
						}
					}
				}
			}
		}
	}
	.navGlobalIn-wrap {
		@include font-size(13);
		.sns-wrap {
			@include sp{
				text-align: center;
				padding-bottom: 60px;
			}
			.ico-ins, .ico-vimeo, .ico-ytb {
				width: 16px;
			}
			.ico-fb {
				width: 7px;
			}
			img {
				margin-right: 5px;
			}
		}
		.navGlobalIn {
			@include sp{
				padding: 60px 0px 50px;
			}
			.navItem {
				margin-bottom: 15px;
				@include sp{
					text-align: center;
					margin: 0;
					padding: 15px 0px;
				}
			}

		}
		@include sp{
			background: rgba(#E5EDEB, 0.8);
			height: 100vh;
			display: none;
			position: fixed;
			top: 0;
			width: 100%;
		}
	}

}

#main-footer{
	.footer{
		text-align: center;
		padding: 65px 0px 65px;
		@include sp{
			padding: 65px 0px 20px;
		}
		.f-logo{
			width: 230px;
		}
		.listFmenu{
			margin-bottom: 115px;
			margin-top: 30px;
			@include sp{
				margin-bottom: 180px;
			}
			li{
				display: inline-block;
				text-align: center;
				margin: 0px 15px;
				@include font-size(13);
				@include sp{
					@include font-size(10);
					margin: 0px 10px;
				}
			}
		}
	}

}

a {
	color: inherit;
	text-decoration: none;
	display: inline-block;
}

a[href^="tel"]{
	@include pc{
		pointer-events: none;
	}
}
img{
	max-width: 100%;
}

@-webkit-keyframes mymove {
	from {opacity: 0}
	to {opacity: 1}
}

/* Standard syntax */
@keyframes mymove {
	from {opacity: 0}
	to {opacity: 1}
}




.scrEvent01{
	//opacity: 0;
	letter-spacing: -40px;
	transition: all 1400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.letter{
	//opacity: 1;
	letter-spacing: 25px;
}

.scrEvent{
	transform: translate(0, 50px);
	opacity: 0;
	transition: all 1400ms cubic-bezier(0.23, 1, 0.32, 1);
}
.fadeIn{
	transform: translate(0, 0);
	opacity: 1;
}
