@charset "UTF-8";
/* ====================================================
reset style
==================================================== */
html {
  overflow-y: scroll;
  font-size: 10px;
  /* Base font size, change to 14px or 16px if needed */
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

img {
  vertical-align: middle;
}

address, caption, cite, code, dfn, em, strong, th, var {
  font-style: normal;
  font-weight: normal;
}

li {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before, q:after {
  content: '';
}

abbr, acronym {
  border: 0;
  font-variant: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input, textarea, select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

/*to enable resizing for IE*/
input, textarea, select {
  *font-size: 100%;
}

/*because legend doesn't inherit in IE */
legend {
  color: #000;
}

del, ins {
  text-decoration: none;
}

/* ====================================================
Font
==================================================== */
/* ====================================================
Position & transform
==================================================== */
/* ====================================================
Color
==================================================== */
/* ====================================================
Sizing
==================================================== */
/* ====================================================
Misc
==================================================== */
/* ====================================================
Base style & container
==================================================== */
body {
  color: black;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  line-height: 1.4;
  font-family: "Times New Roman", "游明朝", "游明朝体", YuMincho, "Yu Mincho", "ＭＳ Ｐ明朝", "MS PMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "sans-serif";
  font-size: 14px;
  font-size: 1.4rem;
  background: #d3e5df;
  transition: background-color 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.container {
  display: block;
  margin: 0 auto;
}

.container.container-sm {
  max-width: 1030px;
  padding: 0 15px;
}

.container.container-md {
  max-width: 1230px;
  padding: 0 20px;
}

.container.container-lg {
  max-width: 1530px;
  padding: 0 25px;
}

@media screen and (max-width: 767px) {
  .pc {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .sp {
    display: none !important;
  }
}

/* ====================================================
Float & clear
==================================================== */
.clearfix {
  *zoom: 1;
}

.clearfix:before {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
  content: "";
  display: table;
}

/*
#overview
モジュールスタイルガイド

これはサイト全体に適用されるモジュールのパーツ群です。
必ずこちらのパーツを使用してコーディングするようにお願いします。
*/
/*
#colors
@背景色 #996600
@文字色 #333
@リンク #ccc
*/
/*
#styleguide
パンくずモジュール

パンくずのモジュールです。

@depulicated
@非推奨
@todo
@common

```
<div class="breadcrumb">
<a href='/'>トップページ</a> <i class='fa fa-angle-right' aria-hidden='true'></i> <span>ページ1</span>
</div>

```

*/
/*
#styleguide
見出しモジュール　識別子はttl

見出しモジュールは、セクションごとの見出しとなる要素（HTML5の分類で、「ヘッディングコンテンツ(Heading Content)」となるh1やh2などの要素、見出しとみなすことが出来そうなp要素の事です。
クラス名として識別子、「 ttl 」をつけて管理します。

```
<h1 class="ttlCom01"><span class="ttlCom01In">見出しモジュール</span></h1>
<h2 class="ttlCom02">見出しモジュール</h2>
<h3 class="ttlCom03">見出しモジュール</h3>

```

*/
/*
#styleguide
テキストモジュール　識別子はtxt

テキストモジュールは、p要素やテキストに該当するspan要素などのコンテンツ内の本文のことです。
基本的に、サイト内のテキストはテキストモジュールに該当します。クラス名として識別子「txt」をつけます。

```
<p class="txtBasic">テキストモジュール、識別子は～</p>

```

*/
/*
#styleguide
リンクモジュール　識別子はlink

リンクモジュールは、基本的にa要素のようにリンクが関係するモジュールの事です。クラス名として識別子「link」をつけます。
通常は、テキスト内にリンクが存在する場合にモジュールとして分ける事が多いです。

```
<a href="" class="linkCmn">OOCSS</a>

```

*/
/*
#styleguide
ボタンモジュール　識別子はbtn

ボタンモジュールは、見た目や役割がボタン上の要素の事です。クラス名をつける要素は特に指定はありませんが、button要素やa要素、a要素を囲むdiv要素に付与するパターンが多いです。
クラス名として識別子「btn」をつけます。

```
<div class="btn btnRed">ボタン</div>
<br>
<div class="btn btnBlue">ボタン</div>

```

*/
/*
#styleguide
リストモジュール　識別子はlist

リストモジュールは、リスト状の同じものが連続して並ぶものが該当します。基本的には、ul、ol、dl要素の様な明確にリスト分けされるものが該当しますが、div要素でも構いません。クラス名として識別子「list」をつけます。
また、クラス名を付与する場所はリストの親要素、つまり連続するリストの子要素がliだとすると、ul要素やol要素に識別子「list」を付与したクラス名をつけます。

```
<ul class="listOption">
	<li class="item">HTML</li>
	<li class="item">CSS</li>
	<li class="item">JavaScript</li>
</ul>

```

*/
/*
#styleguide
ボックスモジュール　識別子はbox

ボックスモジュールは、ボックス状のものが該当します。基本的には見出しやテキストなどの要素を含み込むボーダー付きの箱をボックスモジュールとしてみなします。クラス名として識別子「box」をつけます。

```
<div class="boxReading">
	<h2 class="ttlCom01">ボックスモジュールとは</h2>
		<p class="txtCom01">モジュールコーディングという考え方～</p>
		<a href="" class="linkCom01">続きを読む</a>
</div>

```

*/
/*
#styleguide
グリッドモジュール　識別子はgrid（子要素にはcol)

2カラムや3カラムなど、カラム分けするレイアウトのモジュールが該当します。識別子「grid」を付与します。
子要素には「col」を付与します。基本的に、親に「gridXXX」というメインクラスを付与して、「column-2」や「column-3」などカラム数と対応する数字込みのサブクラスを付与します。

```

<div class="gridCom column-3">
	<div class="col">
		<img class="imageCodingDetail" src="img/XXX.jpg">
	<h2 class="ttlCodingDetail">コーディングの秘訣その1</h2>
	<p class="txtCodingDetail">モジュールコーディングという考え方は～</p>
	</div>
	<div class="col">
		<img class="imageCodingDetail" src="img/XXX.jpg">
	<h2 class="ttlCodingDetail">コーディングの秘訣その1</h2>
	<p class="txtCodingDetail">モジュールコーディングという考え方は～</p>
	</div>
	<div class="col">
		<img class="imageCodingDetail" src="img/XXX.jpg">
	<h2 class="ttlCodingDetail">コーディングの秘訣その1</h2>
	<p class="txtCodingDetail">モジュールコーディングという考え方は～</p>
	</div>
</div>


```

*/
/*
#styleguide
FORMモジュール　

フォーム用の部品になります。

```

<form actione="">
<dl>
	<dt>テキスト</dt>
	<dd><input type="text" name="" value=""></dd>
	<dt>パスワード</dt>
	<dd><input type="password" name="passwd" value=""></dd>
	<dt>ラジオボタン</dt>
	<dd><input type="radio" name="" value="" checked="checked" />テスト1
	<input type="radio" name="" value="" />テスト2
	<input type="radio" name="" value="" />テスト3</dd>
	<dt>チェックボックス</dt>
	<dd><input type="checkbox" name="" value="" checked="checked" />テスト1
	<input type="checkbox" name="" value="" />テスト2
	<input type="checkbox" name="" value="" checked="checked" />テスト3</dd>
	<dt>プルダウン</dt>
	<dd><select name="">
	<option value="" selected="selected">テスト1</option>
	<option value="">テスト2</option>
	<option value="">テスト3</option>
	</select></dd>
	<dt>テキストエリア</dt>
	<dd><textarea name="" rows="5" cols="20">テキストエリア</textarea></dd>
</dl>
<div>
	<input type="button" name="btn1" value="汎用ボタン">
	<input type="reset" name="btn2" value="リセット" >
	<input type="submit" name="btn3" value="送信" >
</div>
</form>


```

*/
/* ====================================================
MODULE
==================================================== */
/*パンくずMODULE*/
/*見出しMODULE*/
/*テキストMODULE*/
.txt-center {
  text-align: center;
}

.txt-right {
  text-align: right;
}

.txt-left {
  text-align: left;
}

/*リンクMODULE*/
/*ボタンMODULE*/
.btn {
  width: 100px;
  height: 50px;
  border: 2px solid #FFCCCC;
  text-align: center;
  line-height: 50px;
}

.btnRed {
  color: red;
  background-color: #FFCCCC;
}

.btnBlue {
  color: white;
  border-color: blue;
  background-color: blue;
}

/*リストMODULE*/
/*ボックスMODULE*/
/*グリッドMODULE*/
/*フォームMODULE*/
.btn-style01 {
  background: #ffffff;
  font-size: 14.5px;
  font-size: 1.45rem;
  color: #000;
  text-align: center;
  display: block;
  max-width: 300px;
  width: 100%;
  line-height: 40px;
  transition: all 0.3s;
}

@media only screen and (max-width: 767px) {
  .btn-style01 {
    margin: auto;
    max-width: 200px;
    line-height: 30px;
  }
}

.btn-style01:hover {
  background: #e6e6e6;
  transition: all 0.3s;
}

.ttl-style01 {
  display: block;
  text-align: center;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .ttl-style01 {
    margin-bottom: 20px;
  }
}

.ttl-style01 span {
  font-size: 20px;
  font-size: 2rem;
  text-align: center;
  letter-spacing: 10px;
  display: inline-block;
  position: relative;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .ttl-style01 span {
    font-size: 20px;
    font-size: 2rem;
  }
}

.ttl-style01 span:after {
  content: '';
  position: absolute;
  height: 2px;
  background: #737373;
  width: 100%;
  left: -3px;
  bottom: -2px;
}

@media only screen and (max-width: 767px) {
  .ttl-style01 span:after {
    left: -5px;
  }
}

.ttl-style02 {
  font-size: 30px;
  font-size: 3rem;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 60px;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .ttl-style02 {
    font-size: 16px;
    font-size: 1.6rem;
    margin-bottom: 30px;
  }
}

.ttl-style02 span {
  font-size: 12px;
  font-size: 1.2rem;
  text-transform: lowercase;
}

.ttl-style03 {
  text-align: center;
  margin-bottom: 140px;
}

@media only screen and (max-width: 767px) {
  .ttl-style03 {
    margin-bottom: 55px;
  }
  .ttl-style03 img {
    width: 50%;
  }
}

.ttl-style03 span {
  padding-bottom: 10px;
}

input, textarea, select {
  background: #f2f2f2;
  border: none;
  display: block;
  width: 100%;
  padding: 0px 10px;
}

textarea {
  resize: none;
  height: 285px;
  padding-top: 10px;
}

@media only screen and (max-width: 767px) {
  textarea {
    height: 190px;
  }
}

input, select {
  height: 35px;
}

#main-header {
  position: fixed;
  z-index: 999;
}

@media only screen and (max-width: 767px) {
  #main-header {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  #main-header {
    top: 40px;
    left: 25px;
  }
}

#main-header .logo-wrap {
  position: relative;
}

@media only screen and (max-width: 767px) {
  #main-header .logo-wrap {
    text-align: center;
    display: block;
    padding: 12px;
  }
}

@media only screen and (max-width: 767px) {
  #main-header .logo-wrap .logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

#main-header .logo-wrap .logo img {
  width: 230px;
}

@media screen and (max-width: 900px) {
  #main-header .logo-wrap .logo img {
    width: 160px;
  }
}

@media only screen and (min-width: 768px) {
  #main-header .logo-wrap .logo img {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  #main-header .logo-wrap .logo img {
    width: 120px;
    display: block;
    margin: auto;
  }
}

#main-header .logo-wrap .menuBar {
  display: none;
}

@media only screen and (max-width: 767px) {
  #main-header .logo-wrap .menuBar {
    z-index: 99;
    position: absolute;
    top: -2px;
    right: 12px;
    display: block !important;
    height: 46px;
  }
  #main-header .logo-wrap .menuBar span {
    height: 2px;
    display: block;
    width: 30px;
    background: #000000;
    position: relative;
    transition: all 0.3s;
  }
  #main-header .logo-wrap .menuBar span:nth-child(1) {
    top: 15px;
  }
  #main-header .logo-wrap .menuBar span:nth-child(2) {
    top: 20px;
  }
  #main-header .logo-wrap .menuBar span:nth-child(3) {
    top: 25px;
  }
  #main-header .logo-wrap .menuBar.on {
    transition: all 0.3s;
  }
  #main-header .logo-wrap .menuBar.on span:nth-child(1) {
    top: 24px;
    transform: rotate(45deg);
  }
  #main-header .logo-wrap .menuBar.on span:nth-child(2) {
    opacity: 0;
  }
  #main-header .logo-wrap .menuBar.on span:nth-child(3) {
    top: 20px;
    transform: rotate(-45deg);
  }
}

#main-header .navGlobalIn-wrap {
  font-size: 13px;
  font-size: 1.3rem;
}

@media only screen and (max-width: 767px) {
  #main-header .navGlobalIn-wrap .sns-wrap {
    text-align: center;
    padding-bottom: 60px;
  }
}

#main-header .navGlobalIn-wrap .sns-wrap .ico-ins, #main-header .navGlobalIn-wrap .sns-wrap .ico-vimeo, #main-header .navGlobalIn-wrap .sns-wrap .ico-ytb {
  width: 16px;
}

#main-header .navGlobalIn-wrap .sns-wrap .ico-fb {
  width: 7px;
}

#main-header .navGlobalIn-wrap .sns-wrap img {
  margin-right: 5px;
}

@media only screen and (max-width: 767px) {
  #main-header .navGlobalIn-wrap .navGlobalIn {
    padding: 60px 0px 50px;
  }
}

#main-header .navGlobalIn-wrap .navGlobalIn .navItem {
  margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  #main-header .navGlobalIn-wrap .navGlobalIn .navItem {
    text-align: center;
    margin: 0;
    padding: 15px 0px;
  }
}

@media only screen and (max-width: 767px) {
  #main-header .navGlobalIn-wrap {
    background: rgba(229, 237, 235, 0.8);
    height: 100vh;
    display: none;
    position: fixed;
    top: 0;
    width: 100%;
  }
}

#main-footer .footer {
  text-align: center;
  padding: 65px 0px 65px;
}

@media only screen and (max-width: 767px) {
  #main-footer .footer {
    padding: 65px 0px 20px;
  }
}

#main-footer .footer .f-logo {
  width: 230px;
}

#main-footer .footer .listFmenu {
  margin-bottom: 115px;
  margin-top: 30px;
}

@media only screen and (max-width: 767px) {
  #main-footer .footer .listFmenu {
    margin-bottom: 180px;
  }
}

#main-footer .footer .listFmenu li {
  display: inline-block;
  text-align: center;
  margin: 0px 15px;
  font-size: 13px;
  font-size: 1.3rem;
}

@media only screen and (max-width: 767px) {
  #main-footer .footer .listFmenu li {
    font-size: 10px;
    font-size: 1rem;
    margin: 0px 10px;
  }
}

a {
  color: inherit;
  text-decoration: none;
  display: inline-block;
}

@media only screen and (min-width: 768px) {
  a[href^="tel"] {
    pointer-events: none;
  }
}

img {
  max-width: 100%;
}

@-webkit-keyframes mymove {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Standard syntax */
@keyframes mymove {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.scrEvent01 {
  letter-spacing: -40px;
  transition: all 1400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.letter {
  letter-spacing: 25px;
}

.scrEvent {
  transform: translate(0, 50px);
  opacity: 0;
  transition: all 1400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.fadeIn {
  transform: translate(0, 0);
  opacity: 1;
}

.mv {
  height: calc(100vh + 80px);
  position: relative;
  padding: 0px 0px 100px;
}

@media only screen and (max-width: 767px) {
  .mv {
    height: calc(100vh);
    padding-bottom: 0;
    padding-top: 42px;
  }
}

.mv .mv-inner {
  max-width: 1200px;
  margin: auto;
}

.mv .mv-inner .ttl-mv {
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);
  font-size: 38px;
  font-size: 3.8rem;
  width: 0px;
  word-break: break-all;
  z-index: 99;
}

.mv .mv-inner .ttl-mv span {
  display: inline-block;
  opacity: 0;
}

.mv .mv-inner .ttl-mv span.on {
  animation: mymove 2s;
}

.mv .mv-inner .ttl-mv span.on.span02 {
  animation-delay: 0.2s;
}

.mv .mv-inner .ttl-mv span.on.span03 {
  animation-delay: 0.4s;
}

.mv .mv-inner .ttl-mv span.on.span04 {
  animation-delay: 0.6s;
}

.mv .mv-inner .ttl-mv span.on.span05 {
  animation-delay: 0.8s;
}

.mv .mv-inner .ttl-mv span.on.span06 {
  animation-delay: 1s;
}

.mv .mv-inner .ttl-mv span.on.span07 {
  animation-delay: 1.2s;
}

.mv .mv-inner .ttl-mv span.on.span08 {
  animation-delay: 1.4s;
}

.mv .mv-inner .ttl-mv span.on.span09 {
  animation-delay: 1.6s;
}

@media screen and (max-width: 900px) {
  .mv .mv-inner .ttl-mv {
    left: 15%;
  }
}

@media only screen and (max-width: 767px) {
  .mv .mv-inner .ttl-mv {
    font-size: 20px;
    font-size: 2rem;
    left: 50%;
    font-weight: bold;
  }
}

@media only screen and (max-width: 767px) {
  .mv img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    height: calc(100vh - 42px);
  }
  .mv .mv-video {
    position: absolute;
    height: 0;
    overflow: hidden;
    top: 40px;
    padding-bottom: 100vh;
    width: 100%;
    right: 0;
  }
}

@media screen and (max-width: 900px) {
  .mv .mv-video {
    right: 4% !important;
  }
}

@media only screen and (min-width: 768px) {
  .mv .mv-video {
    position: relative;
    padding-bottom: calc(100vh - 60px);
    height: 0;
    top: 40px;
    right: 10%;
    overflow: hidden;
    max-width: 100%;
  }
  .mv .mv-video iframe,
  .mv .mv-video object,
  .mv .mv-video embed,
  .mv .mv-video video {
    position: absolute;
    top: 0;
    right: 0;
    width: unset;
    height: 100%;
  }
}

.ss-wedding {
  padding-bottom: 100px;
}

@media only screen and (max-width: 767px) {
  .ss-wedding {
    padding-bottom: 0px;
  }
}

.ss-wedding .ss-wedding-inner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 17px;
  font-size: 1.7rem;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .ss-wedding .ss-wedding-inner {
    flex-direction: column-reverse;
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.ss-wedding .ss-wedding-inner .wedding-l {
  width: 58.3%;
  z-index: 1;
  background: url("../img/index/kusakinafilm-1-11.jpg") no-repeat center/cover;
  background-position: left 70% center;
}

@media only screen and (max-width: 767px) {
  .ss-wedding .ss-wedding-inner .wedding-l {
    height: 350px;
  }
}

.ss-wedding .ss-wedding-inner .wedding-l img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: none;
}

@media only screen and (max-width: 767px) {
  .ss-wedding .ss-wedding-inner .wedding-l {
    width: 100%;
  }
}

.ss-wedding .ss-wedding-inner .wedding-r {
  width: calc(100% - 58.3%);
  padding: 0px 30px 5.41% 4.16%;
  line-height: 2;
  z-index: 2;
}

@media screen and (max-width: 1024px) {
  .ss-wedding .ss-wedding-inner .wedding-r {
    padding: 0px 10px 5.41% 2.16%;
  }
}

@media screen and (max-width: 800px) {
  .ss-wedding .ss-wedding-inner .wedding-r {
    padding: 0px 15px 5.41% 4.16%;
  }
}

@media only screen and (max-width: 767px) {
  .ss-wedding .ss-wedding-inner .wedding-r {
    width: 100%;
    padding: 55px 15px 55px 30px;
  }
}

.ss-wedding .ss-wedding-inner .wedding-r .ttl {
  font-size: 45px;
  font-size: 4.5rem;
  line-height: 1.4;
  color: #737373;
  text-decoration: underline;
  margin: 170px 0px 160px -180px;
  text-underline-position: under;
}

@media screen and (max-width: 1060px) {
  .ss-wedding .ss-wedding-inner .wedding-r .ttl {
    font-size: 32px;
    font-size: 3.2rem;
  }
}

.ss-wedding .ss-wedding-inner .wedding-r .ttl span.span02 {
  display: inline-block;
  margin-left: 80px;
  text-decoration: underline;
  text-underline-position: under;
}

@media only screen and (max-width: 767px) {
  .ss-wedding .ss-wedding-inner .wedding-r .ttl {
    font-size: 22px;
    font-size: 2.2rem;
    margin: 0 0px 45px;
  }
  .ss-wedding .ss-wedding-inner .wedding-r .ttl span {
    margin-left: 20px;
  }
  .ss-wedding .ss-wedding-inner .wedding-r .ttl span.span02 {
    margin-left: 40px;
  }
}

.ss-movie {
  padding-bottom: 100px;
}

@media only screen and (max-width: 767px) {
  .ss-movie {
    padding-bottom: 0px;
  }
}

.ss-movie.ss-photo .ss-movie-inner {
  flex-direction: row-reverse;
}

.ss-movie.ss-photo .ss-movie-inner .ss-movie-r {
  overflow: hidden;
}

.ss-movie.ss-photo .ss-movie-inner .ss-movie-r a {
  overflow: hidden;
  display: inline-block;
}

.ss-movie.ss-photo .ss-movie-inner .ss-movie-r img {
  transition: all 0.3s;
}

.ss-movie.ss-photo .ss-movie-inner .ss-movie-r:hover img {
  transform: scale(1.1);
  transition: all 0.3s;
}

.ss-movie.ss-photo .listMovies {
  padding: 0px 6.6%;
}

.ss-movie.ss-photo .listMovies .item {
  margin-right: 5.83%;
  overflow: hidden;
  width: calc((100% - 11.66%) / 3);
}

.ss-movie.ss-photo .listMovies .item .img-thumb {
  overflow: hidden;
}

.ss-movie.ss-photo .listMovies .item .img-thumb img {
  transition: all 0.3s;
}

.ss-movie.ss-photo .listMovies .item:hover img {
  transform: scale(1.1);
  transition: all 0.3s;
}

.ss-movie .ss-movie-inner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.ss-movie .ss-movie-inner .ss-movie-l {
  width: calc(100% - 59.2%);
  padding: 5.83%;
  font-size: 17px;
  font-size: 1.7rem;
}

.ss-movie .ss-movie-inner .ss-movie-l .ttl-style01 {
  text-align: left;
  margin-bottom: 60px;
}

.ss-movie .ss-movie-inner .ss-movie-l .ttl-style01 span {
  font-size: 60px;
  font-size: 6rem;
  color: #737373;
  display: inline-block;
  letter-spacing: 25px;
}

@media only screen and (max-width: 767px) {
  .ss-movie .ss-movie-inner .ss-movie-l .ttl-style01 span {
    letter-spacing: 15px;
  }
}

@media screen and (max-width: 1060px) {
  .ss-movie .ss-movie-inner .ss-movie-l .ttl-style01 span {
    font-size: 40px;
    font-size: 4rem;
  }
}

.ss-movie .ss-movie-inner .ss-movie-l .ttl-style01 span:after {
  left: -10px;
}

.ss-movie .ss-movie-inner .ss-movie-l .btn-style01 {
  margin-top: 65px;
}

@media only screen and (max-width: 767px) {
  .ss-movie .ss-movie-inner .ss-movie-l {
    width: 100%;
    padding: 35px 10px;
    text-align: center;
    font-size: 14px;
    font-size: 1.4rem;
  }
  .ss-movie .ss-movie-inner .ss-movie-l .ttl-style01 {
    text-align: center;
    margin-bottom: 30px;
  }
  .ss-movie .ss-movie-inner .ss-movie-l .ttl-style01 span {
    font-size: 30px;
    font-size: 3rem;
  }
  .ss-movie .ss-movie-inner .ss-movie-l .btn-style01 {
    margin: 35px auto 0px;
  }
}

.ss-movie .ss-movie-inner .ss-movie-r {
  width: 59.2%;
}

@media only screen and (max-width: 767px) {
  .ss-movie .ss-movie-inner .ss-movie-r {
    width: 100%;
  }
  .ss-movie .ss-movie-inner .ss-movie-r .description {
    font-size: 16px;
    font-size: 1.6rem;
    text-align: center;
    padding: 15px 0px 40px;
  }
}

.ss-movie .ss-movie-inner .ss-movie-r img {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .ss-movie .ss-movie-inner .ss-movie-r img {
    height: 100%;
    object-fit: cover;
  }
}

.ss-movie .ss-movie-inner .ss-movie-r .img-thumb {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .ss-movie .ss-movie-inner .ss-movie-r .img-thumb {
    padding-bottom: 56.25%;
  }
}

.ss-movie .ss-movie-inner .ss-movie-r .img-thumb iframe {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.ss-movie .ss-movie-inner .ss-movie-r .img-thumb .vp-center {
  align-items: baseline;
}

.ss-movie .ss-movie-inner .ss-movie-r .img-thumb div#player {
  height: 100%;
}

.ss-movie .listMovies {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 100px;
  padding: 0px 3.75%;
}

@media only screen and (max-width: 767px) {
  .ss-movie .listMovies {
    margin-top: 30px;
    display: block;
  }
}

.ss-movie .listMovies.vimeoVideo .item .img-thumb {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.ss-movie .listMovies.vimeoVideo .item iframe {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.ss-movie .listMovies .item {
  margin-right: 4.16%;
  width: calc((100% - 8.32%) / 3);
}

.ss-movie .listMovies .item img {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .ss-movie .listMovies .item img {
    height: 100%;
    object-fit: cover;
  }
}

.ss-movie .listMovies .item:last-child {
  margin-right: 0;
}

.ss-movie .listMovies .item .description {
  text-align: center;
  margin-top: 30px;
  font-size: 15px;
  font-size: 1.5rem;
}

.ss-office {
  padding-bottom: 100px;
}

@media only screen and (max-width: 767px) {
  .ss-office {
    padding-bottom: 70px;
  }
}

.ss-office .mv-video {
  position: relative;
}

.ss-office .mv-video video {
  display: block;
  width: 100%;
}

.ss-office .ss-office-inner .listChoice {
  margin-top: 6.67%;
  margin-bottom: 8.3%;
  display: flex;
  flex-wrap: nowrap;
  padding: 0px 10.83%;
}

@media screen and (max-width: 1000px) {
  .ss-office .ss-office-inner .listChoice {
    padding: 0px 3.8%;
  }
}

@media only screen and (max-width: 767px) {
  .ss-office .ss-office-inner .listChoice {
    margin: 50px 0px 50px;
    padding: 0px 40px;
    display: block;
  }
}

.ss-office .ss-office-inner .listChoice .item {
  margin-right: 8.3%;
  text-align: center;
  width: calc((100% - 16.6%) / 3);
  font-size: 15px;
  font-size: 1.5rem;
}

@media screen and (max-width: 1000px) {
  .ss-office .ss-office-inner .listChoice .item {
    margin-right: 4%;
    width: calc((100% - 8%)/3);
  }
}

.ss-office .ss-office-inner .listChoice .item a {
  display: unset;
}

@media only screen and (max-width: 767px) {
  .ss-office .ss-office-inner .listChoice .item {
    margin-right: 0;
    font-size: 13px;
    font-size: 1.3rem;
    margin-bottom: 50px;
    width: 100%;
  }
  .ss-office .ss-office-inner .listChoice .item a {
    display: block;
  }
}

.ss-office .ss-office-inner .listChoice .item:last-child {
  margin-right: 0;
}

.ss-office .ss-office-inner .listChoice .item .img-thumb {
  margin-bottom: 25px;
}

@media only screen and (max-width: 767px) {
  .ss-office .ss-office-inner .listChoice .item .img-thumb {
    margin-bottom: 15px;
    padding: 0px 20px;
  }
}

.ss-office .ss-office-inner .listChoice .item .img-thumb {
  overflow: hidden;
}

.ss-office .ss-office-inner .listChoice .item .img-thumb img {
  transition: all 0.3s;
}

.ss-office .ss-office-inner .listChoice .item:hover img {
  transform: scale(1.1);
  transition: all 0.3s;
}

.ss-office .ss-office-inner .office-main-content {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .ss-office .ss-office-inner .office-main-content {
    flex-direction: column-reverse;
  }
}

.ss-office .ss-office-inner .office-main-content .office-right {
  width: 65%;
}

@media only screen and (max-width: 767px) {
  .ss-office .ss-office-inner .office-main-content .office-right {
    width: 100%;
  }
}

.ss-office .ss-office-inner .office-main-content .office-right img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ss-office .ss-office-inner .office-main-content .office-left {
  width: 35%;
  padding: 0px 30px 0px 5.83%;
}

@media only screen and (max-width: 767px) {
  .ss-office .ss-office-inner .office-main-content .office-left {
    width: 100%;
    padding: 25px 40px 0px;
  }
  .ss-office .ss-office-inner .office-main-content .office-left > p {
    padding: 0px 15px;
  }
}

.ss-office .ss-office-inner .office-main-content .office-left .ttl-style01 {
  margin-bottom: 50px;
}

@media only screen and (max-width: 767px) {
  .ss-office .ss-office-inner .office-main-content .office-left .ttl-style01 {
    margin-bottom: 30px;
  }
}

.ss-office .ss-office-inner .office-main-content .office-left .ttl-style01 span {
  font-size: 25px;
  font-size: 2.5rem;
  letter-spacing: 10px;
}

@media only screen and (max-width: 767px) {
  .ss-office .ss-office-inner .office-main-content .office-left .ttl-style01 span {
    font-size: 20px;
    font-size: 2rem;
  }
}

.ss-office .ss-office-inner .office-main-content .office-left p {
  font-family: "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  margin-bottom: 40px;
  font-size: 13px;
  font-size: 1.3rem;
}

@media only screen and (max-width: 767px) {
  .ss-office .ss-office-inner .office-main-content .office-left p {
    margin-bottom: 30px;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.ss-office .ss-office-inner .office-main-content .office-left iframe {
  width: 100%;
  height: 215px;
}

@media only screen and (max-width: 767px) {
  .ss-office .ss-office-inner .office-main-content .office-left iframe {
    height: 155px;
  }
}

.contact .contact-inner {
  max-width: 680px;
  padding: 0px 15px;
  margin: auto;
  font-size: 12px;
  font-size: 1.2rem;
}

.contact .contact-inner > p {
  font-size: 13px;
  font-size: 1.3rem;
}

@media only screen and (max-width: 767px) {
  .contact .contact-inner > p {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.contact .contact-inner .ttl-style01 {
  margin-bottom: 70px;
}

.contact .contact-inner .ttl-style01 span {
  font-size: 25px;
  font-size: 2.5rem;
}

@media only screen and (max-width: 767px) {
  .contact .contact-inner .ttl-style01 span {
    font-size: 20px;
    font-size: 2rem;
  }
}

.contact .contact-inner .contact-form {
  margin-top: 6.67%;
  font-size: 12px;
  font-size: 1.2rem;
}

.contact .contact-inner .contact-form .contact-item {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  border-top: 1px solid #737373;
  padding: 25px 0px;
}

@media only screen and (max-width: 767px) {
  .contact .contact-inner .contact-form .contact-item {
    display: block;
    padding: 15px 0px;
  }
}

.contact .contact-inner .contact-form .contact-item:last-child, .contact .contact-inner .contact-form .contact-item:first-child {
  border-top: 0;
}

.contact .contact-inner .contact-form .contact-item .item-l {
  width: 27.5%;
  padding-top: 10px;
}

@media only screen and (max-width: 767px) {
  .contact .contact-inner .contact-form .contact-item .item-l {
    width: 100%;
    margin-bottom: 10px;
    padding-top: 0;
  }
}

.contact .contact-inner .contact-form .contact-item .item-r {
  width: 72.5%;
}

@media only screen and (max-width: 767px) {
  .contact .contact-inner .contact-form .contact-item .item-r {
    width: 100%;
    position: relative;
  }
}

.contact .contact-inner .contact-form .contact-item .item-r .btn-style01 {
  border: 1px solid #737373;
}

@media only screen and (max-width: 767px) {
  .contact .contact-inner .contact-form .contact-item .item-r .btn-style01 + .err {
    position: static;
    padding-left: 0;
    text-align: center;
    margin-top: 15px;
  }
}

.contact .contact-inner .contact-form .contact-item .err {
  color: #ff0000;
  margin-top: 8px;
}

@media only screen and (max-width: 767px) {
  .contact .contact-inner .contact-form .contact-item .err {
    font-size: 10px;
    font-size: 1rem;
    position: absolute;
    top: -33px;
    padding-left: 65px;
  }
}

@media (max-width: 320px) {
  .no-ip5 {
    display: none !important;
  }
}

#googleMap {
  width: 100%;
  height: 215px;
}

@media only screen and (max-width: 767px) {
  #googleMap {
    height: 155px;
  }
}

#page-photo02 {
  background-color: #D1E1EC;
}

#page-photo02 .img-weding {
  max-width: 560px;
  margin: 0 auto;
  padding-bottom: 180px;
}

@media only screen and (max-width: 767px) {
  #page-photo02 .img-weding {
    max-width: 100%;
    padding: 0 25px;
    padding-bottom: 50px;
  }
}

#page-photo02 .img-weding img {
  margin-bottom: 20px;
  pointer-events: none;
}

@media only screen and (max-width: 767px) {
  #page-photo02 .img-weding img {
    margin-bottom: 15px;
  }
}

#page-photo02 .img-weding img:last-child {
  margin-bottom: 0;
}

.sec-photo {
  padding-top: 300px !important;
}

@media only screen and (max-width: 767px) {
  .sec-photo {
    padding-top: 85px !important;
  }
}

.item-white {
  max-width: 930px;
  margin: auto;
  padding: 0px 15px;
  margin-bottom: 80px;
}

@media only screen and (max-width: 767px) {
  .item-white {
    margin-bottom: 35px;
  }
}

.item-white:last-child {
  margin-bottom: 0;
}

.plan .item-white-inner {
  padding: 65px 60px;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .plan .item-white-inner {
    display: block;
    padding: 25px 15px;
  }
}

.plan .left {
  width: 40%;
  color: #000;
  font-size: 25px;
  font-size: 2.5rem;
  letter-spacing: 15px;
}

@media only screen and (max-width: 767px) {
  .plan .left {
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
    font-size: 18px;
    font-size: 1.8rem;
    letter-spacing: 12px;
  }
}

.plan .right {
  width: 60%;
  color: #5B5959;
}

@media only screen and (max-width: 767px) {
  .plan .right {
    width: 100%;
  }
}

.plan .item {
  font-size: 15px;
  font-size: 1.5rem;
  margin-bottom: 60px;
}

@media only screen and (max-width: 767px) {
  .plan .item {
    font-size: 12px;
    font-size: 1.2rem;
    margin-bottom: 35px;
  }
}

.plan .item span {
  font-size: 20px;
  font-size: 2rem;
  display: block;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .plan .item span {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.plan .item p {
  line-height: 1.6;
  position: relative;
}

.plan .item p:before {
  display: inline-block;
  content: '';
  width: 12px;
  height: 12px;
  background-color: #5B5959;
  margin-right: 5px;
}

@media only screen and (max-width: 767px) {
  .plan .item p:before {
    width: 8px;
    height: 8px;
  }
}

.plan .item p.no-arround {
  font-size: 12px;
  font-size: 1.2rem;
}

.plan .item p.no-arround:before {
  display: none;
}

@media only screen and (max-width: 767px) {
  .plan .item p.no-arround {
    font-size: 10px;
    font-size: 1rem;
  }
}

.plan .item:last-child {
  margin-bottom: 0;
}

.plan.style02 {
  display: block;
}

.plan.style02 .left,
.plan.style02 .right {
  width: 100%;
}

.plan.style02 .left {
  margin-bottom: 25px;
}

.plan.style02 .right span {
  display: block;
  margin-bottom: 50px;
  color: #000;
  font-size: 15px;
  font-size: 1.5rem;
}

@media only screen and (max-width: 767px) {
  .plan.style02 .right span {
    font-size: 12px;
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
}

.plan.style02 .right a {
  max-width: 215px;
}

.sub-ttl {
  text-align: center;
  font-size: 21px;
  font-size: 2.1rem;
  margin-bottom: 140px;
}

@media only screen and (max-width: 767px) {
  .sub-ttl {
    font-size: 14px;
    font-size: 1.4rem;
    margin-bottom: 65px;
  }
}

#page-plan {
  background-color: #E6EBE3;
  color: #5B5959;
}

#page-plan .sec-planMovie {
  padding-bottom: 185px;
}

@media only screen and (max-width: 767px) {
  #page-plan .sec-planMovie {
    padding-bottom: 50px;
  }
}

#page-qa {
  background-color: #E6EBE3;
}

.qa-request {
  max-width: 870px;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 185px;
}

.qa-request li {
  padding-bottom: 30px;
  padding-top: 30px;
  border-bottom: 1px solid #ccc;
}

@media only screen and (max-width: 767px) {
  .qa-request li {
    padding-bottom: 17px;
    padding-top: 17px;
  }
}

.qa-request li:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}

.qa-request li:first-child {
  padding-top: 0;
}

.qa-request li p {
  font-size: 20px;
  font-size: 2rem;
  padding-left: 25px;
  color: #5B5959;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .qa-request li p {
    font-size: 16px;
    font-size: 1.6rem;
    padding-left: 20px;
  }
}

.qa-request li p::before {
  content: "";
  background-image: url("../img/faq/question.png");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  width: 22px;
  height: 25px;
  top: 50%;
  left: 0;
  position: absolute;
  transform: translate(0, -50%);
}

@media only screen and (max-width: 767px) {
  .qa-request li p::before {
    width: 17px;
    height: 20px;
  }
}

.qa-request li .answer {
  background-color: #fff;
  padding: 25px 40px;
  margin-top: 20px;
  font-size: 15px;
  font-size: 1.5rem;
  display: none;
}

@media only screen and (max-width: 767px) {
  .qa-request li .answer {
    padding: 12px 30px;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.qa-request li .answer:before {
  left: 13px;
  background-image: url("../img/faq/answer.png");
}

@media only screen and (max-width: 767px) {
  .qa-request li .answer:before {
    left: 10px;
  }
}

.qa-request .toggle {
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .qa-request {
    padding-bottom: 50px;
  }
}

.page-content {
  padding-top: 300px;
  padding-bottom: 185px;
}

@media only screen and (max-width: 767px) {
  .page-content {
    padding-top: 150px;
    padding-bottom: 90px;
  }
}

.page-content .movie-inner > p {
  font-size: 21px;
  font-size: 2.1rem;
  text-align: center;
  margin-bottom: 35px;
  color: #5b5959;
}

@media only screen and (max-width: 767px) {
  .page-content .movie-inner > p {
    font-size: 14px;
    font-size: 1.4rem;
    margin-bottom: 20px;
  }
}

.page-content .ttl-style01 {
  margin-bottom: 140px;
}

.page-content .ttl-style01 span {
  font-size: 77px;
  font-size: 7.7rem;
}

@media only screen and (max-width: 767px) {
  .page-content .ttl-style01 {
    margin-bottom: 70px;
  }
  .page-content .ttl-style01 span {
    font-size: 34px;
    font-size: 3.4rem;
  }
}

.page-content .listFillter {
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-size: 1.8rem;
  margin-bottom: 50px;
  margin-top: 140px;
  flex-wrap: wrap;
}

@media only screen and (max-width: 767px) {
  .page-content .listFillter {
    font-size: 12px;
    font-size: 1.2rem;
    margin: 70px 0px 50px;
    justify-content: unset;
    padding: 0px 30px;
  }
}

.page-content .listFillter li:hover span:after, .page-content .listFillter li:hover a:after, .page-content .listFillter li.active span:after, .page-content .listFillter li.active a:after {
  display: block;
}

.page-content .listFillter li span, .page-content .listFillter li a {
  display: inline-block;
  padding: 0px 10px;
  position: relative;
  cursor: pointer;
}

.page-content .listFillter li span:after, .page-content .listFillter li a:after {
  content: "";
  position: absolute;
  bottom: 2px;
  width: 100%;
  height: 1px;
  left: 0;
  background: #737373;
  display: none;
}

@media only screen and (max-width: 767px) {
  .page-content .listFillter li span, .page-content .listFillter li a {
    padding: 5px 10px;
  }
}

.page-content .listMovies {
  padding: 0px 2.5%;
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 767px) {
  .page-content .listMovies {
    padding: 0px 20px;
  }
}

.page-content .listMovies .tab-content {
  margin-right: 3.33%;
  overflow: hidden;
  width: calc((100% - 6.66%) / 3);
  margin-bottom: 4%;
}

@media only screen and (max-width: 767px) {
  .page-content .listMovies .tab-content {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.page-content .listMovies .tab-content:nth-child(3n) {
  margin-right: 0;
}

.page-content .listMovies .tab-content .img-thumb01 {
  overflow: hidden;
}

.page-content .listMovies .tab-content .img-thumb01 img {
  transition: all 0.3s;
}

.page-content .listMovies .tab-content:hover img {
  transform: scale(1.1);
  transition: all 0.3s;
}

.page-content .listMovies .tab-content .img-thumb {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.page-content .listMovies .tab-content iframe {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.page-content .listMovies .tab-content .item-content {
  padding: 0px 10px;
  text-align: center;
}

.page-content .listMovies .tab-content .item-content .ttl {
  font-weight: bold;
  font-size: 18px;
  font-size: 1.8rem;
  text-align: center;
  padding: 15px 0px 20px;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .page-content .listMovies .tab-content .item-content .ttl {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.page-content .listMovies .tab-content .img-thumb {
  overflow: hidden;
}

.page-content .listMovies .tab-content .img-thumb img {
  transition: all 0.3s;
}

.page-content .listMovies .tab-content:hover img {
  transform: scale(1.1);
  transition: all 0.3s;
}

#page-movie {
  background: #d9deeb;
}

#page-photo {
  background: #d1e1ec;
}

@media only screen and (max-width: 767px) {
  #page-photo .listMovies {
    padding: 0px 40px;
  }
}
